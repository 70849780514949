@media (max-width: 1300px) {
  .rmdp-day,
  .rmdp-week-day {
    height: 30px !important;
    width: 30px !important;
  }
}

@media (max-height: 450px), (max-width: 450px) {
  .rmdp-day,
  .rmdp-week-day {
    height: 28px !important;
    width: 28px !important;
  }
}

@media (max-width: 1200px) {
  .searchtabs .nav .nav-item .nav-link {
    padding: 10px 10px;
  }
  .service_btn .mainbtn,
  .service_btn .deletebtn {
    display: block;
    text-align: center;
  }
  .searchrowbox {
    border: 1px solid $primary_light;
    background: $light;
    border-radius: 0.5rem;
  }
}
@media (max-width: 992px) {
  .notification_dropdown .dropdown-menu ul li .delete_btn{
    opacity: 1;
    transform: scale(.9);
  }
  .searchtabs {
    padding: 0 0.5rem;
  }

  .menu > ul {
    gap: 20px;
  }
  .trip_sidebar_btn {
    transform: translate(calc(100% - 40px), -50%);
  }
  .pagination {
    overflow: auto;
    justify-content: flex-start;
  }
  .searchtabs .nav .nav-item {
    min-width: 140px;
  }
  html {
    font-size: 15px;
  }

  .menu > ul > li:nth-child(1),
  .menu > ul > li:nth-child(2) {
    margin-right: 0;
  }

  .menu > ul > li .mainbtn {
    width: 100%;
    display: block;
    border-radius: 5px;
  }

  .dropdown-menu.submenu {
    margin: 1rem auto 0;
    width: 50%;
    background: linear-gradient(45deg, #d6ecf3 0%, #f8f8f9 100%);
    box-shadow: none;
  }

  .menu > ul > li {
    padding: 0.4rem 0;
    display: block;
  }
  .menu {
    text-align: center;
  }
  .stepper-item .step-name {
    min-height: 36px;
  }
  .content__image {
    height: 15rem;
  }
  .filterstickybtn {
    display: block;
  }
  .stickysidebar {
    position: static;
    top: 0rem;
  }
  .sidefilter {
    border-radius: 0px;
    position: fixed;
    top: 0;
    z-index: 2000000002 !important;
    left: -15rem;
    width: 15rem;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    transition: all 0.3s;
  }
  .sidefilter.show {
    transition: all 0.3s;
    left: 0;
  }
  .membercardbox {
    text-align: left;
    display: flex;
    align-items: center;
    &.myfollowerscard {
      display: block;
      .position-relative {
        display: flex;
        align-items: center;
        .form-check {
          position: static;
          flex-shrink: 0;
          margin-right: 10px;
        }

        .membercardboximg {
          flex-shrink: 0;
          margin-right: 10px;
          img {
            height: 4rem;
            width: 4rem;
          }
        }
        .membercardboxdata {
          flex-grow: 1;
        }
      }
    }
    & .membercardboximg {
      margin-right: 10px;
    }
    & .membercardboxdata {
      padding-top: 0;
    }
  }
  .amenitiesicons img {
    height: 20px;
  }
  .footul li:not(:last-child) {
    margin-bottom: 10px;
  }
  .footul li a {
    font-size: 13px;
  }
  .footlist h5 {
    margin: 0 0 1rem;
  }
  .container,
  .container-fluid {
    padding: 0 15px;
  }

  .searchtabs .nav {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .searchtabs .nav::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .searchtabs .nav li {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .header {
    min-height: 70px;
}
  #hemudl954rl81675835284172.widget-visible{
    transform: scale(0.7) !important;
  }
  .themetoggle button {
    background: rgba(255, 217, 0, 0.05);
    transform: scale(1.4);
    border: 1px solid #f7f7f7;
    .sun {
      color: #ffbc00;
    }
  }
  .steprightarrow svg {
    transform: rotate(90deg);
    font-size: 2rem;
  }
  .logo img {
    height: 60px;
  }
  .menu > ul > li.userbtn {
    margin-right: 0;
  }
  .flightlistcard:not(:last-child) {
    margin-bottom: 1rem;
  }
  .searchradiobtn .form-check-inline label {
    padding: 0.5rem 1rem;
    padding-left: 2rem;
  }
  .searchradiobtn .form-check-inline input[type="radio"] {
    left: 0.7rem;
  }
  .content__image {
    height: 15rem;
  }
  html {
    font-size: 14px;
  }
  .tripformdata p:not(:last-child) {
    margin: 0 0 0.5rem;
  }
  .stepper-wrapper {
    white-space: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .stepper-item {
    min-width: 130px;
  }
  .stepper-item::before {
    top: 1.4rem;
  }
  .searchlistingmainbox {
    display: none;
  }
  .searchtravellerdropboxhotel .dropdown-menu {
    width: 100%;
  }

  .input-box label {
    min-height: auto;
  }
  .amenitiesicons img {
    height: 15px;
  }
  .react-input-emoji--button svg {
    width: 15px;
  }
  .friend-comment__pic,
  .profile__pic {
    width: 20px;
    height: 20px;
  }
  .commentsendbtn {
    font-size: 15px;
    width: 30px;
    line-height: 28px;
  }
  .commentbtndiv {
    right: 10px;
  }
  .react-input-emoji--button {
    padding-right: 0;
  }
  .profilecardmain {
    display: none;
  }

  .searchtravellerdropbox .dropdown-menu {
    width: 100%;
  }
  .heading {
    margin: 0 0 1rem;
  }
  .stepbox h4 {
    font-size: 18px;
  }
  .steprightarrow {
    margin: 1rem 0;

    & i:before {
      content: "\f309";
    }
  }

  .liamfeatureimg {
    margin-bottom: 3rem;
  }

  .topright > ul > li > a,
  .topright > ul > li > button {
    font-size: 1.4rem;
  }
  .topright > ul > li:not(:last-child) {
    margin-right: 1rem;
  }
  .submenu li a {
    padding: 0.6rem 1.5rem;
  }
  .searchtabs {
    padding: 0 10px;
    margin: -15px -15px 1rem;
  }
  .searchbanner {
    padding: 1rem 0;
  }
  .servicedata h4 {
    font-size: 2.5rem;
  }
  // .servicedata p {
  //   font-size: 1.8rem;
  // }
  .topheader {
    background-color: yellow;
  }
  .slick-prev {
    left: 14px;
  }
  .slick-next {
    right: 14px;
  }

  .timeline .timelinelist:nth-child(even) .row {
    flex-direction: row;
  }
  .languagechangediv select {
    width: 100%;
  }
  .searchtabs .nav .nav-item {
    min-width: 140px;
  }
  .room_image img {
    height: 90px;
    object-fit: cover;
}
}

@media (max-width: 575px) {
  .editprofiletabs.profiletabspage {
    .nav-tabs {
      display: block;
      background-color: $light;
      border: none;
      border-radius: 10px;
      overflow: hidden;
      .nav-item .nav-link {
        text-align: left;
        border: none;
        padding: 10px 20px;
        &.active {
          background-color: #478bca;
          color: #fff;
        }
      }
    }
  }
  .flightlistcard .airlineimg {
    width: 3rem;
    height: 3rem;
  }
  .airlinetextround {
    text-align: left;
  }
  .taxisearchtabs .nav-tabs {
    justify-content: center;
  }
  .homegallerybox img {
    height: 200px;
  }
  .menubarbtn {
    span {
      width: 25px;
      height: 2px;
    }
  }
  .sliderbigimagebox {
    img {
      height: 300px;
    }
  }
  .filterstickybtn.filterstickybtnreturn {
    bottom: 6rem;
  }
  .roundtrip_card .roundtrip_card_label .flight_description h6 {
    font-size: 12px;
  }
  .verified_box p {
    font-size: 1rem;
  }
  .vendortabs.taxisearchtabs {
    & .nav-tabs {
      justify-content: center;
    }
  }
  .airlinetextround span {
    margin: 2px auto;
    width: 35px;
    &.onwayline {
      margin: 2px 0;
    }
  }

  .languagechangediv {
    text-align: center;
  }
  .dropdown-menu.submenu {
    width: 245px;
  }
  .author__name .feelingtext {
    display: block;
  }
  .sidefilter {
    left: -20rem;
    width: 20rem;
  }
  .flatofferbigdata {
    padding: 2rem;
  }
  .flatofferbig .flatpackagedata {
    padding: 1rem;
  }
  .footlist h5 {
    font-size: 1rem;
  }
  .searchradiobtn .form-check-inline label {
    padding: 0.5rem 1rem;
    padding-left: 2.5rem;
    font-size: 0.8rem;
  }
  .searchradiobtn .form-check-inline input[type="radio"] {
    left: 0.7rem;
    top: 0.6rem;
  }
  .searchrowbox > .searchinputlabel + input[type="text"],
  .searchrowbox > .searchinputlabel + input[type="date"],
  .searchrowbox .rmdp-input {
    height: auto;
    font-size: 1rem;
  }
  .searchinputdata {
    height: auto;
    font-size: 1rem;
  }
  .searchinputdata span {
    font-size: 0.8rem;
  }
  .searchinputpara,
  .searchinputpara1 {
    font-size: 0.9rem;
  }
  .searchtabs .nav .nav-item .nav-link {
    padding: 0.8em 0.5rem;
  }
  .searchtabs .nav .nav-item {
    min-width: 100px;
  }
  .searchtabs .nav i {
    font-size: 1.5rem;
  }
  .searchtabs .nav .nav-item .nav-link span {
    font-size: 0.8rem;
    margin-top: 4px;
  }
  .logo img {
    height: 3.4rem;
  }
  .redeemrequestbox .input-box p {
    font-size: 12px;
  }
  .commentbtndiv {
    right: 10px;
  }
  .commentsendbtn {
    width: 25px;
    line-height: 25px;
  }

  .faqmainbox h5 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  .faqsearch {
    width: 90%;
  }

  .testirating i {
    font-size: 1.2rem;
  }
  .testimonialsbox p {
    margin: 0 0 1rem;
  }
  .languagechangediv select {
    margin: 0 auto;
    display: block;
    width: 60%;
    height: 2rem;
  }

  .room_image img {
    height: 90px;
    object-fit: cover;
}
}
@media (max-width: 480px) {
  .sliderwiththumbbox .thumbnail-slider-wrap .slick-slide img {
    height: 70px;
  }
  .sliderbigimagebox {
    img {
      height: 200px;
    }
  }
  .viewtriplistdivmain .daydiv {
    width: 40px;
    line-height: 40px;
    margin-right: 10px;
  }
}
