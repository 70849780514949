@import "var";

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
  padding: 20px 0px !important;
}


p:last-child {
  margin-bottom: 0;
}
.coupon_container{
  overflow: scroll;
  overflow-x: hidden;
  height: auto;
}
a.text-danger.hornbill_nav_link {
  font-size: 16px;
  font-weight: 700;
}

.whatsapp_fixed_icon {
  position: fixed;
  bottom: 20px;
  left: 22px;
  z-index: 3;
}




.whatsapp_fixed_icon a {
  height: 60px;
  width: 60px;
  background-color: #25d366;
  font-size: 30px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flightoptionboxdata1{
  &:hover{
    color: #478bca;
  }
}



.newImage {
  height: 18px;
  position: absolute;
  top: -20px;
  right: -12px;
}

.mobile_newImage {
  right: 108px;
  width: 45px;
  position: absolute;
  top: 59px;
}


.phone_input input{
padding: 20px 10px !important;
font-size: 17px !important;
border: 2px solid #478bca;
width: 100% !important;
}

.phone_input input:focus{
  border: 1px solid #478bca !important;
  box-shadow: 0px 0px 1px 1px #478bca !important;
  }
.phone_input .flag-dropdown:focus{
  border: 1px solid #478bca !important;
  box-shadow: 0px 0px 1px 1px #478bca !important;
}
  

.tooltip{
  --bs-tooltip-zindex: 99999999999;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 10px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
  box-shadow: none;
}

body {
  font-family: $font1;
  color: $secondary;
  background-color: var(--white);
}

.loginbtn::-webkit-inner-spin-button,
.loginbtn::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.downloadmbappdiv {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 2000000001 !important;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.2);

  & img {
    width: 50px;
  }

  & h5 {
    color: $white;
    margin: 0 0 3px;
    font-size: 1rem;
    font-weight: 600;
  }

  & p {
    color: $white;
    font-size: 0.8rem;
    line-height: 1.2;
  }

  & .close {
    color: #000;
    font-size: 10px;
    border: none;
    padding: 0;
    border-radius: 50%;
    display: block;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    width: 25px;
    line-height: 25px;
    transform: translate(-25%, -50%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.form-label {
  margin-bottom: 0.3rem;
  font-size: 14px;
  font-weight: 500;
  color: #939393;
  display: block;
}

.servicebox {
  background-color: var(--white);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.location_address{
  word-break: break-word;
}

.menubtn {
  float: right;
  display: none;
}

.menubtn button {
  background: none;
  border: none;
  color: $primary;
  font-size: 2rem;
  outline: none;
  padding: 0;
}

.languagechangediv select {
  width: 15rem;
  height: 2rem;
  background: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 0 1rem;
  border-radius: 5rem;
  color: #fff;
}

.searchbanner {
  padding: 4rem 0 1.4rem;
}

.languagechangediv select option {
  color: #000;
}

.trendings_search ul {
  white-space: nowrap;
  display: block;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.trendings_search ul::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.toprightbtns {
  & > ul {
    & > li {
      display: inline-block;
      vertical-align: middle;
      & > a,
      & > button {
        display: block;
        background: none;
        border: none;
        padding: 0;
        outline: none;
        color: $white;
        font-weight: 500;
      }
    }

    & > li:not(:last-child) {
      margin-right: 2rem;
    }
  }
}
.coininputbox {
  input {
    padding-right: 85px;
    &[readonly] {
      background-color: #f7f7f7;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 11px 15px;
    line-height: 1;

    &.apply_btn {
      color: $success;
    }
    &.remove_btn {
      color: $danger;
    }
  }
}
.notification_dropdown {
  & .dropdown-toggle {
    padding: 0;
    border: none;
    svg {
      font-size: 20px;
      color: #fff;
    }
    &:active {
      padding: 0;
      border: none;
    }
  }
  .dropdown-menu {
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: none;
    & ul {
      li {
        position: relative;
        .notification_btn {
          width: 100%;
          padding: 10px;
          display: flex;
          background-color: transparent;
          border: 0;
          text-align: left;
          border-radius: 10px;
          white-space: wrap;
          .title {
            font-size: 14px;
            margin-bottom: 5px;
            color: #717171;
          }
          .date {
            font-size: 12px;
            color: $secondary;
          }
        }
        .delete_btn {
          color: $primary;
          width: 35px;
          background: #fff !important;
          border-radius: 50%;
          font-size: 14px;
          line-height: 31px;
          border: 1px solid #ddd;
          box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
          opacity: 0;
          transition: all 0.3s;
          position: absolute;
          right: 10px;
          bottom: 10px;
          z-index: 1;
          &:hover {
            background-color: #f8f8f8 !important;
          }
        }
        .activecircle {
          text-align: center;
          display: none;
          span {
            width: 10px;
            height: 10px;
            background-color: $primary;
            border-radius: 50%;
            display: block;
            margin: 0 auto;
          }
        }
        &.active {
          .activecircle {
            display: block;
          }
          .title {
            color: var(--black);
          }
          .date {
            color: $primary;
          }
        }
        &:hover {
          button {
            background-color: #f8f8f8;
          }
          .delete_btn {
            opacity: 1;
            transition: all 0.3s;
          }
        }
      }
    }
  }
  .btn:hover {
    color: #fff;
  }
  .dropdown-toggle::after {
    display: none;
  }

  .btn sup.badge {
    margin-left: 5px;
    top: -5px;
  }
}

.topright a svg {
  font-size: 1rem + 0.4rem;
  vertical-align: middle;
  margin-right: 5px;
}

.topright a span {
  vertical-align: middle;
  font-weight: 500;
}
.logo {
  img {
    height: 60px;
  }
}

.sidedarebox {
  .input-box label {
    min-height: auto;
  }
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.header {
  min-height:   112px;
  .topheader {
    padding: 0.5rem 0;
  }
  &.sticky{
    .fixedheader {
      position: fixed;
      top: 0;
      animation: slideDown 0.35s ease-out;
    }
  }
  .fixedheader {
    width: 100%;
    z-index: 100;
  }
}

.scrolled-down {
  transform: translateY(-50px);
  display: none;
  transition: all 0.3s;
  opacity: 0.7;
}


.midheader {
  background-color: var(--white);
  padding: 5px 0;
  position: relative;
  z-index: 10;
  box-shadow: var(--box-shadow);
}

.menubarbtn {
  background-color: transparent;
  position: relative;
  padding: 10px;
  border: none;

  span {
    width: 30px;
    height: 3px;
    display: block;
    background-color: $primary;
    border-radius: 10px;
  }
  span:not(:last-child) {
    margin-bottom: 6px;
  }
}
.footlogo1{
  height: 70px;
}
.footlogo2{
  height: 40px;
}
.footlogo3{
  height: 60px;
}
.sidebarmenu {
  ul {
    li {
      margin-bottom: 5px;
      a {
        display: block;
        padding: 10px 10px;
        color: var(--black);
        border-radius: 5px;
        &.active {
          background-color: $light;
          color: $primary;
        }
      }
    }
  }
}
.sidemenu {
  .offcanvas-body {
    overflow-y: hidden;
    .sidebarmenuscroll {
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
}
.sidemenu.offcanvas.offcanvas-start {
  width: 300px;
}
.menu {
  display: flex;
  justify-content: flex-end;
  & > ul {
    display: flex;
    gap: 30px;
    align-items: center;
    .nav-item {
      display: inline-block;
      .nav-link {
        color: var(--black);
        &:hover,
        &.active {
          color: $primary;
        }
      }
      &.userbtn {
        display: flex;
        gap: 10px;
      }
    }
  }
}
.rounded-4 {
  border-radius: 0.6rem !important;
}

.rounded-5 {
  border-radius: 0.8rem !important;
}

.userbtn .dropdown-toggle::after {
  display: none;
}

.userbtn .dropdown-toggle svg {
  font-size: 20px;
  margin-left: 5px;
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu.feeddropmenu {
  width: 10rem;
  box-shadow: $box-shadow;
  border: none;

  & li {
    & a,
    button {
      display: block;
      padding: 0.2rem 1.2rem !important;
      color: var(--black);
      font-size: 0.9rem;
      background: none;
      border: none;
      width: 100%;
      text-align: left;

      &:hover {
        background: $gradient_primary;
        color: #fff !important;
      }
    }
  }
}

.after_login_menu .dropdown-menu {
  width: 14rem;
  box-shadow: var(--box-shadow);
  border: none;

  & a,
  button {
    display: block;
    padding: 10px 20px;
    color: var(--black);
    font-size: 15px;
    background: none;
    border: none;
    width: 100%;
    text-align: left;

    & svg {
      margin-right: 1rem;
    }

    &:hover {
      background: $gradient_primary;
      color: var(--white);
    }
  }
}
.timelinemain {
  position: relative;
}
.timeline_arrow_icon {
  z-index: 10;
  text-align: center;
  background: transparent;
  color: #fff;
  border: none;
  position: absolute;
  top: 50%;
  font-size: 30px;
  &.timeline_arrow_left {
    left: 0;
    transform: translate(-150%, -50%);
  }
  &.timeline_arrow_right {
    right: 0;
    transform: translate(150%, -50%);
  }
  &.disabled {
    opacity: 0.2;
  }
}
.playbtncenter {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
}
.timelinethumbbox {
  text-align: center;
  color: #fff;
  font-style: italic;
  padding: 10px 0;
  cursor: pointer;
}

.timeline_thumbnail .slick-current .timelinethumbbox {
  color: gold;
  transform: scale(1.5);
}

.timelinebg {
  background-size: cover;
  background-position: center;
  position: relative;
  &:before {
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .container {
    position: relative;
  }
}
.searchtabs {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  padding: 0 2rem;
  margin: -3.5rem 1rem 1rem;
  position: relative;
  & .nav {
    & .nav-item {
      text-align: center;
      flex-grow: 1;
      flex-basis: 0;

      & .nav-link {
        display: block;
        padding: 0.8em 1rem;
        cursor: pointer;

        & svg {
          color: $primary;
          font-size: 1.5rem;
        }

        & span {
          display: block;
          color: var(--black);
          margin-top: 0.5rem;
          font-size: 14px;
        }

        &.active {
          color: var(--white);
          background: $gradient_primary;

          &.active svg,
          &.active span {
            color: #fff;
          }
        }
      }
    }
  }
}
.search_slide_btn {
  position: absolute;
  top: 50%;
  button {
    background: rgba(195, 234, 255, 0.5);
    border: none;
    border-radius: 50%;
    line-height: 23px;
    width: 25px;
    height: 25px;
    text-align: center;
  }
  &.prev_arrow {
    left: 0;
    transform: translate(-30%, -50%);
  }
  &.next_arrow {
    right: 0;
    transform: translate(30%, -50%);
  }
}
.searchrowbox {
  border: 2px solid #eee;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: block;
  margin: 0;
  height: 100%;
  cursor: inherit;
  &:focus-within {
    border-color: $primary;
  }
}

.searchinputlabel {
  color: $primary;
  text-transform: uppercase;
  font-size: 0.6rem;
  margin: 0;
}

.blurtext {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.btn-outline-primary {
  &:hover {
    color: #fff !important;
  }
}

.input-group.qtycounterbox {
  & .form-control {
    -moz-appearance: textfield;
  }

  & .form-control::-webkit-outer-spin-button,
  & .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .btn:disabled {
    border-color: #cdcdcd;
    color: #333;
    background: #e5e5e5;
  }
}
.searchrowbox {
  & > .searchinputlabel + input[type="text"],
  .react-autosuggest__input {
    background-color: transparent;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    font-weight: 500;
    line-height: 1;
    color: var(--black);
  }

  & .rmdp-input {
    background-color: transparent;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    font-weight: 500;
    line-height: 1;
    color: var(--black);

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.searchrowbox select {
  width: 100%;
  height: 2.5rem;
  border: none;
  outline: none;
  padding: 0;
  font-weight: 500;
  line-height: 1;
  color: var(--black);
  background: transparent;
}
.tawk-min-container .tawk-button-circle.tawk-button-large {
  width: 50px !important;
  height: 50px !important;
  .tawk-min-chat-icon {
    width: 25px !important;
  }
}
.searchinputpara {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  margin-top: 0;
  min-height: 22px;
  color: $secondary;
}

.modal {
  z-index: 999999999999;
}

.searchinputdata {
  color: var(--black);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 6px;
}
.listingbelowcitydata {
  color: var(--black);
  p {
    margin-bottom: 10px;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
    li:not(last-child) {
      margin-bottom: 10px;
    }
  }
  a {
    color: $primary;
    font-size: 0.9em;
  }
}
.searchinputdata span {
  font-size: 1rem;
  font-weight: 400;
}

.searchinputpara1 {
  font-size: 0.6rem;
  margin: 4px 0 0;
  line-height: 1.45;
}

.table-hover > tbody > tr:hover > * {
  color: var(--black);
}

.verified_box {
  display: flex;
  align-items: center;

  & .icon {
    width: 40px;
    background-color: $success;
    border-radius: 50%;
    text-align: center;
    padding: 8px 0;

    & svg {
      font-size: 20px;
      color: #fff;
      line-height: 40px;
    }
  }

  & p {
    font-size: 1.2rem;
    color: var(--black);

    & b {
      font-weight: 600;
    }
  }
}

.detail_para_format {
  white-space: normal;
  word-break: break-word;
}

.searchinputpara1list {
  font-size: 0.6rem;
  margin: 4px 0 0;
  line-height: 1.45;
  background: #fbfbfb;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 6px;
}

.searchradiobtn .form-check-inline {
  &:last-child {
    margin-right: 0;
  }

  position: relative;
  padding: 0;
}

.searchradiobtn .form-check-inline input[type="radio"] {
  position: absolute;
  left: 1.5rem;
  margin: 0;
  top: 0.7rem;
}

.searchradiobtn .form-check-inline label {
  padding: 0.5rem 1.2rem;
  background: transparent;
  border-radius: 5rem;
  cursor: pointer;
  color: var(--black);
  font-weight: 500;
  padding-left: 3rem;
}

.searchradiobtn .form-check-inline input:checked ~ label {
  background: #e0f2ff;
  color: $primary;
}

.forButtonAndMessage{
  .btn{
    padding: 2px 0px !important;
    border-radius: 5px !important;
  }
}

.searchbtn .mainbtn {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 2rem;
}

.trendings_search li {
  display: inline-block;
  color: #fff;
}

.trendings_search li:not(:last-child) {
  margin-right: 1rem;
}

.trendings_search li a {
  display: block;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.3rem 1rem;
  border-radius: 5rem;
}

.trendings_search li a svg {
  font-size: 1rem;
  vertical-align: middle;
  margin: 0 1rem;
}

.offerimg img {
  border-radius: 1rem;
  width: 100%;
}

.servicedata h6 {
  font-weight: 500;
  color: var(--black);
  margin: 0 0 0.6rem;
}

.servicedata p {
  margin: 0 0 1rem;
  font-size: 13px;
}

.servicedata .checknow {
  font-weight: 500;
  color: $primary;
  font-size: 0.9rem;
}

.flatofferbigdata {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 5rem 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
}

.flatofferbigdata h5 {
  color: var(--white);
  margin: 0 0 1rem;
}

.flatofferbigdata h4 {
  color: #ffeb00;
  font-size: 8rem;
  margin: 0 0 2rem;
  font-weight: 600;
  line-height: 1;
}

.flatofferbigdata h4 span {
  display: inline-block;
  font-size: 3rem;
  line-height: 1;
}

.rmdp-day,
.rmdp-week-day {
  height: 34px !important;
  width: 34px !important;
}

.flatofferbig {
  overflow: hidden;

  & img {
    transition: all 0.3s;
  }

  &:hover img {
    transform: scale(1.05);
    transition: all 0.3s;
  }

  & .flatpackagedata {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: var(--white);
    font-size: 1.5rem;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.flatpackagedata:hover {
  color: var(--white);
}

.homegallerybox {
  transition: all 0.3s;
  overflow: hidden;
  border-radius: 20px;

  & img {
    transition: all 0.3s;
    height: 200px;
    object-fit: cover;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s;
    }
  }
}
.filters_btns {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  button {
    background-color: #dcefff;
    border: none;
    border-radius: 50px;
    font-size: 12px;
    padding: 4px 10px;
    letter-spacing: 0.5px;
    color: $primary;
    svg {
      margin-left: 5px;
    }
  }
}
.flightoptionbox {
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }

  & img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    margin-right: 1rem;
  }
}

.flightoptionboxdata h4 {
  color: $primary;
}

.flightoptionboxdata p {
  margin: 0;
  font-size: 15px;
}

.flightoptionboxdata p a {
  color: var(--black);
}

.flightoptionboxdata p a:hover {
  color: $primary;
}

.owl-nav button {
  outline: none;
  position: absolute;
  top: 45%;
}

.owl-nav button svg {
  background: var(--white);
  width: 40rem;
  text-align: center;
  border-radius: 50%;
  line-height: 40rem;
  box-shadow: var(--box-shadow);
  color: var(--black);
}

.owl-nav button i:hover {
  background: $gradient_primary;
  color: var(--white);
}

.owl-nav .owl-prev {
  left: -20rem;
}

.owl-nav .owl-next {
  right: -20rem;
}

.liamfeatureimg {
  text-align: center;
}

.liamfeatureimg {
  width: 20rem;
  margin: 0 auto;
}

.liamfeatureimg img {
  width: 100%;
}

.liamfeaturedaa h4 {
  font-weight: 600;
  color: var(--black);
}

.liamfeaturedaa p {
  color: var(--black);
  margin: 0 0 2rem;
}

.owl-dots {
  text-align: center;
  margin-top: 3rem;
}

.owl-dots button {
  outline: none;
  margin: 0 0.5rem;
}

.owl-dots button span {
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  border: 2rem solid $primary;
  display: block;
}

.owl-dots button.active span {
  background-color: $primary;
}

.footlist h5 {
  font-weight: 600;
  color: var(--black);
  margin: 0 0 1.4rem;
}

.footul li:not(:last-child) {
  margin-bottom: 0.8rem;
}

.footul li a {
  font-size: 14px;
  color: var(--black);
}

.footer a:hover {
  color: $primary;
}

.footcontact {
  & .icon {
    width: 2rem;
    padding: 4px 0;
    text-align: center;
    background: $gradient_primary;
    border-radius: 50%;

    & svg {
      font-size: 1rem;
      color: #fff;
    }
  }

  & label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    letter-spacing: 1px;
    margin: 0;
    color: var(--black);
  }

  & p {
    margin: 0.3rem 0 0;
    color: var(--black);
    font-size: 0.8rem;
  }
}

.footsocial {
  & li {
    display: inline-block;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    & a,
    button {
      width: 2.6rem;
      text-align: center;
      border: 2px solid #ddd;
      background-color: transparent;
      border-radius: 50%;
      display: block;
      line-height: 2.3rem;
      font-size: 1.4rem;
      color: var(--black);
      &:hover {
        background: $gradient_primary;
        border-color: $primary;
        color: var(--white);
      }
    }
  }
}

.footnewsletter {
  position: relative;
}

.footnewsletter input[type="email"],
.footnewsletter input[type="tel"],
.footnewsletter input[type="text"] {
  width: 100%;
  height: 40px;
  background-color: var(--white);
  border: 1px solid transparent;
  outline: none;
  padding: 0 1rem;
  padding-right: 3rem;
  border-radius: 4rem;
}

.footnewsletter input[type="email"]:focus {
  border-color: $primary;
}

.footnewsletter button {
  position: absolute;
  right: 4px;
  top: 4px;
  background: linear-gradient(45deg, #478bca 0%, #54c6f1 100%);
  color: var(--white);
  border: none;
  outline: none;
  border-radius: 50%;
  width: 32px;
  text-align: center;
  line-height: 30px;
  display: block;
}

.footpaymenticon img {
  height: 2rem;
}

.botfooter {
  border-top: 1px solid #ddd;
  padding-top: 2rem;
  margin-top: 2rem;
}

.botfooter p {
  color: var(--black);
}

.downloadappbox h3 {
  color: var(--black);
  font-weight: 700;
}

.downloadappbox p {
  color: var(--black);
}

.appbutton {
  color: #fff;
  background: $gradient_primary;

  display: flex;
  justify-content: center;
  border-radius: 5rem;
  padding: 0.6rem 1.6rem;
  align-items: center;

  &:hover {
    background: transparent;
    color: $primary;
    outline: 2px solid $primary;
  }

  & svg {
    font-size: 2rem;
  }

  & label {
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin: 0;
    pointer-events: none;
    display: block;
  }

  & p {
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.appdownloadfillnumber input[type="tel"] {
  box-shadow: var(--box-shadow);
  padding-left: 3rem;
}

.appdownloadfillnumber .countrycode {
  position: absolute;
  left: 15px;
  color: var(--black);
  font-weight: bold;
  padding: 9px 0;
}

.blogbox {
  background: var(--white);
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
}

.blogimg {
  position: relative;
}

.blogimg img {
  width: 100%;
  border-radius: 1rem;
}

.blogdate {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  background: $gradient_primary;
  color: var(--white);
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 0.5rem;
  line-height: 1;
  padding: 0.6rem 1rem;
}

.blogdata {
  padding-top: 1.5rem;
}

.blogdata h3 {
  font-size: 2rem;
  line-height: 1.4;
  color: var(--black);
  margin: 0 0 1rem;
}

.blogdata p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 0 1.5rem;
  font-size: 1.4rem;
}

.blogdata .readmorebtn {
  color: $primary;
  font-weight: 500;
}

.stepbox {
  text-align: center;
}

.stepbox p {
  color: $primary;
  font-weight: bold;
  margin: 0 0 1rem;
}

.stepbox h4 {
  margin: 0;
  color: var(--black);
  font-weight: 600;
}

.steprightarrow {
  text-align: center;
}

.steprightarrow svg {
  color: $primary;
  font-size: 4rem;
}

.testimonialsbox {
  background: var(--white);
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
}

.testimg {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100%;
}

.testimg img {
  width: 100%;
}

.testimonialsbox p {
  color: var(--black);
  margin: 0 0 1rem;
  min-height: 80px;
}

.testimonialsbox .testiname {
  color: $primary;
  font-weight: 700;
  margin: 0 0 0.5rem;
  line-height: 1;
}

.testimonialsbox .testilocation {
  color: #787878;
  font-size: 0.9rem;
  min-height: 20px;
}

.testirating svg {
  margin: 0 2px;
  color: #ffd400;
}

a[data-fancybox="gallery"] {
  outline: none;
}

.slick-dots {
  position: static !important;
  bottom: 0;
  margin-top: 1rem;
}

.arrow_icon.arrow_right.arrow_btn_right{
  right: 0;
  transform: translateX(100%);
}

.arrow_icon.arrow_left.arrow_btn_left{
  left: 0;
  transform: translateX(-100%);
}

.arrow_icon {
  width: 40px;
  height: 40px;
  z-index: 10;
  text-align: center;
  background: var(--white);
  color: #478bca;
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arrow_left {
  left: 0;
  transform: translateX(50%);
}

.arrow_right {
  right: 0;
  transform: translateX(-50%);
}

.searchtravellerdropbox {
  & .dropdown-menu {
    width: 40rem;
    padding: 0;
  }
}

.searchtravellerdropboxhotel {
  height: 100%;

  & .dropdown,
  button,
  .searchrowbox {
    height: 100%;
  }

  & .dropdown-menu {
    width: 20rem;
    padding: 0;
  }
}

.input-box label {
  display: block;
  font-size: 12px;
  font-weight: 500;
  min-height: 21px;
  margin: 0 0 0.2rem;
}

.input-box input[type="text"],
.input-box input[type="number"],
.input-box input[type="time"],
.input-box input[type="tel"],
.input-box input[type="email"],
.input-box input[type="file"],
.input-box input[type="date"],
.input-box input[type="password"],
.input-box textarea,
.input-box select {
  width: 100%;
  height: 2.35rem;
  border: 1px solid #ddd;
  background: $secondary_light;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 0.8rem;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.input-box1 select {
  width: 100%;
  height: 2.35rem;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 0.8rem;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.form-control {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.input-box textarea {
  height: 7rem;
  display: block;
  padding: 0.5rem;
}

.form-control:focus {
  border-color: $primary;
  box-shadow: none;
}

.posttextareabtns {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}

.input-sm.input-box select {
  height: 30px;
}

.input-box input[type="text"]:focus,
.input-box input[type="tel"]:focus,
.input-box input[type="file"]:focus,
.input-box input[type="date"]:focus,
.input-box input[type="email"]:focus,
.input-box input[type="password"]:focus,
.input-box textarea:focus,
.input-box select:focus {
  background-color: var(--white);
  border-color: $primary;
}

.input-box select {
  padding-right: 2.4rem;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.searchtravellerdropbox .input-box select {
  height: 2.5rem;
}

.policydata {
  & ol {
    margin: 0;

    & li:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.title_header {
  text-align: center;
  padding: 1.4rem 0;
  background: $gradient_light;

  & h1 {
    color: var(--black);
    font-weight: 600;
    margin: 0;
  }
}

.counterbox {
  padding: 1rem;

  & svg {
    color: #fff;
    font-size: 3rem;
  }

  & h3 {
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    margin: 0 0 0.2rem;
  }

  & p {
    color: #fff;
    margin: 0;
  }
}

.post_video_box {
  width: 100%;

  & video {
    width: 100%;
    display: block;
    height: 443px;
    background: #000;
  }
}

.slick-list {
  -webkit-transition: height 250ms linear;
}

.timeline .timelinelist:nth-child(even) .row {
  flex-direction: row-reverse;
}

.careeraccordion .accordion-item {
  margin-bottom: 1rem;
  border: none;
  &:last-child {
    margin: 0;
  }
}

.careeraccordion .accordion-button {
  background: $gradient_primary;
  color: #fff;
  border-radius: 1rem;
  box-shadow: none;
}

.careeraccordion .accordion-item:first-of-type .accordion-button,
.careeraccordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 1rem;
}

.careeraccordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.contactmap {
  width: 100%;
  height: 30rem;
}

.contactmap iframe {
  width: 100%;
  height: 100%;
}

.faqsearch {
  width: 30rem;
  margin: 0 auto;
  position: relative;
}

.faqsearch input {
  width: 100%;
  height: 3rem;
  outline: none;
  border: none;
  padding: 0 1.5rem;
  border-radius: 0.5rem;
  padding-right: 6rem;
}

.faqsearch button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: no-repeat;
  color: var(--black);
  border: none;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
}

.faqmainbox h5 {
  color: var(--black);
  margin: 0 0 1rem;
}

.faqmainbox .accordion .accordion-button {
  padding: 1rem 0;
  font-weight: bold;
  color: $primary;
  background-color: var(--white);
}
.sliderbigimagebox {
  img {
    height: 400px;
    object-fit: cover;
  }
}
.faqmainbox .accordion .accordion-button.collapsed {
  color: var(--black);
  font-weight: normal;
}
.btn-close {
  color: var(--black);
}

.accordion-item {
  background: var(--white);
}

.faqmainbox .accordion .accordion-button:focus {
  box-shadow: none;
}

.faqmainbox .accordion .accordion-body {
  padding: 1rem 0;
  color: var(--black);
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.faqmainbox .collapse:not(.show) {
  border-top: none;
}

.accordion-flush .accordion-collapse {
  border-top: 1px solid #ddd;
}

.loginpop {
  position: relative;
  padding: 1.6rem;
}

button.btn-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}

.floating-input {
  outline: 0;
  padding: 0.2rem 0.6rem;
  display: block;
  width: 100%;
  height: 2.5rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.floating-input1 {
  outline: 0;
  padding: 0.2rem 0.6rem;
  display: block;
  width: 100%;
  height: 2.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.floating-input:focus {
  border-color: $primary;
  background-color: white;
}

.checkboxlist input {
  display: none;
}

.checkboxlist label {
  cursor: pointer;
  display: block;
  margin: 0;
  position: relative;
  padding-left: 2rem;
}

.checkboxlist input:checked + label:before {
  opacity: 1;
  transition: all 0.3s;
  transform: scale(1) rotate(45deg);
  visibility: visible;
}

.checkboxlist label:before {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  width: 7px;
  opacity: 0;
  height: 11px;
  border-right: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
  z-index: 3;
  transform: scale(3) rotate(180deg);
  top: 4px;
  visibility: hidden;
  transition: all 0.3s;
}

.checkboxlist input:checked + label:after {
  background: $primary;
  border-color: $primary;
  transition: all 0.3s;
}

.checkboxlist label:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  width: 16px;
  top: 0.2rem;
  height: 16px;
  border: 1px solid #ddd;
  transition: all 0.3s;
  border-radius: 2px;
}

.signupbox {
  padding: 0 3rem;
  text-align: center;
}

.signupboxinner {
  overflow: hidden;
}

.signupboxinner p {
  position: relative;
}

.signupboxinner p:before {
  content: "";
  display: block;
  height: 1px;
  width: 100rem;
  position: absolute;
  right: 57%;
  background: #ddd;
  top: 10px;
}

.signupboxinner p:after {
  content: "";
  display: block;
  height: 1px;
  width: 100rem;
  position: absolute;
  left: 57%;
  background: #ddd;
  top: 10px;
}

.loginpop .mainbtn {
  padding: 1rem 3rem;
}

.login-form h3 {
  color: var(--black);
}

.sidebar-right .nav-item .nav-link {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding: 0.8rem 2rem;
  color: #042452;
  // border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  font-weight: 600;
}
.nav > li {
  position: relative;
  display: block;
}
.profilemenu {
  padding: 0.6rem 0;
  box-shadow: var(--box-shadow);
  background: var(--white);
  border-radius: 1rem;
  overflow: hidden;

  & > ul > li:not(:last-child) {
    border-bottom: 1px solid #f7f7f7;
  }

  & ul {
    & li {
      & a,
      button {
        display: flex;
        padding: 10px 20px;
        color: var(--black);
        font-size: 14px;
        width: 100%;
        background: no-repeat;
        border: none;
        align-items: center;

        & svg {
          margin-right: 1rem;
          color: $primary;
          font-size: 1em;
        }

        &:hover,
        &.active {
          background: $gradient_primary;
          color: #fff;

          & svg {
            color: white;
          }
        }

        &.active svg {
          color: #fff;
        }
      }
    }

    & li.profilesubmenumain {
      & > button {
        & .dropdownicon {
          color: $primary;
          margin-left: auto;
          margin-right: 0;
        }

        &.active .dropdownicon {
          transform: rotate(-180deg);
          transition: all 0.3s;
          color: white;
        }

        &:hover .dropdownicon {
          color: white;
        }
      }
    }
  }
}

.profilesubmenu {
  background: $gradient_light;
}

.profilesubmenu li a svg {
  margin-right: 1rem;
  color: $primary;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.profilesubmenu li a {
  display: flex;
  padding: 8px 20px;
  color: var(--black);
  font-size: 13px;
}

.profilesubmenu li a:hover,
.profilesubmenu li a.active {
  background: $gradient_primary;
  color: var(--white);
}

.profilesubmenu li a:hover i,
.profilesubmenu li a.active svg {
  color: var(--white);
}

.profilecard {
  box-shadow: var(--box-shadow);
  background: var(--white);
  border-radius: 1rem;
  overflow: hidden;
}

.profilecardbanner {
  position: relative;

  overflow: hidden;
  img {
    height: 160px;
    object-fit: cover;
  }
}

.profilepicdiv {
  width: 7rem;
  margin: -3.5rem auto 0;
  position: relative;
  z-index: 1;
  height: 7rem;
  background: #fff;
  border-radius: 50%;
}

.profilepicdiv img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.profileuploadbtn {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  background: $gradient_primary;
  color: #fff;
  width: 2.5rem;
  display: block;
  text-align: center;
  border-radius: 50%;
  line-height: 2.5rem;
  box-shadow: var(--box-shadow);

  & input[type="file"] {
    display: none;
  }
}

.profilecardbanner .profileuploadbtn {
  top: 1rem;
  right: 1rem;
  bottom: auto;
}

.profileuploadbtn:hover {
  color: var(--white);
}

.profilecarddata {
  padding: 1rem;
  text-align: center;
}

.profilecarddata h3 {
  margin: 0 0 0.6rem;
  color: var(--black);
  font-size: 1.2rem;
  font-weight: 700;
}

.profilecarddata h4 {
  font-size: 15px;
  margin: 0 0 0.6rem;
  color: $primary;
}

.profilecarddata h5 {
  font-size: 14px;
  margin: 0;
  color: #a5a5a5;
}

.profilecardfollowbox {
  text-align: center;
  display: flex;
  padding: 0.6rem;
  background: $gradient_primary;
}

.profilecardfollowboxinn {
  flex-grow: 1;
  flex-basis: 0;
}

.profilecardfollowboxinn h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 0.2rem;
}

.profilecardfollowboxinn p {
  color: #fff;
  font-size: 14px;
}

.profilecardfollowboxinn:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.addpostimg {
  width: 2.5rem;
  margin-right: 1rem;
}

.addpostimg img {
  width: 100%;
}

.addpostlink {
  position: relative;
}

.addpostlink .postuploadbutton {
  width: 100%;
  text-align: left;
  height: 2.5rem;
  border-radius: 5rem;
  border: none;
  background: $gradient_light;
  outline: none;
  color: var(--black);
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.addpostlink svg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  color: $primary;
  font-size: 1.5rem;
  height: 40px;
  width: 40px;
  padding: 10px;
}

.editprofiletabs {
  & .nav-tabs {
    margin-bottom: 1rem;

    & .nav-item {
      flex-grow: 1;
      text-align: center;

      & .nav-link {
        color: var(--black);
        border-radius: 0;
        border: none;
        border-bottom: 2px solid transparent;
        display: block;
        padding: 0.8rem 1rem;
        width: 100%;
        cursor: pointer;
        background: transparent;
        &.active {
          border-color: $primary;
          color: $primary;
        }
      }
    }
  }
}

.rmdp-container {
  display: block !important;
}

.checkimg {
  max-height: 20rem;
  margin: 0 auto;
  display: block;
  width: auto;
  max-width: 100%;
}

.prefencelistbox .form-switch {
  padding: 0;
  margin: 0;
  position: relative;
}

.prefencelistbox .form-switch label {
  color: var(--black);
  display: block;
  background: $gradient_light;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  padding-right: 8rem;
  cursor: pointer;
}

.prefencelistbox .form-switch .form-check-input {
  float: none;
  margin: 0.7rem;
  width: 4rem;
  height: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.notificationlist {
  position: relative;
  .notification_detail_button {
    padding: 1rem;
    display: flex;
    width: 100%;
    background: #f7f7f7;
    border-radius: 0.5rem;
    border: 0;
    text-align: left;
  }
  &.active {
    .notification_detail_button {
      background: $gradient_light;
    }
  }
  .removenotiicon {
    color: $primary;
    border: none;
    background: none;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    border-radius: 50%;
    width: 30px;
    font-size: 14px;
    line-height: 28px;
    background-color: #fff;
    &:hover {
      background-color: $primary;
      color: #fff;
    }
  }
}
.detail_para_format {
  p {
    white-space: pre-line;
  }
}
.notificationlist:not(:last-child) {
  margin-bottom: 10px;
}

.notificationlist h5 {
  font-size: 1rem;
  color: var(--black);
  margin: 0 0 0.5rem;
}

.notificationlist h5:hover a {
  color: $primary;
}

.notificationlist p {
  font-size: 0.8rem;
}

.feedpostimg {
  width: 100%;
}

.post {
  background: var(--white);
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
}

.post__footer {
  padding: 0 1rem;
}

.post__header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.header__left {
  flex: 1;
  display: flex;
  align-items: center;
}

.post__author-pic {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  flex-shrink: 0;
}

.post__author-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.post__author {
  flex-grow: 1;
}

.author__name {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);

  & .feelingtext {
    margin-left: 5px;
    font-weight: 400;
    color: $secondary;
    font-size: 0.9em;
  }
}

.post__date {
  display: block;
  float: left;
  font-size: 12px;
  color: var(--black);
}

/* Header right */
.post__options {
  padding: 0.5rem;
  border-radius: 50%;
  line-height: 50%;
  transition: background-color 0.2s ease;
  cursor: pointer;
  color: var(--black);
  &:active {
    border-color: transparent !important;
  }

  &:hover {
    color: $primary !important;
    background-color: #f2f8ff !important;
  }

  & svg {
    font-size: 20px;
  }
}

/****************
 * POST CONTENT * 
 ****************/

.content__paragraph {
  color: var(--black);
  padding: 0 1rem 1rem;
}

.content__image {
  width: 100%;
  height: 25rem;
  object-fit: contain;
}

.post__content .slick-dots {
  margin: 0;
}

.content__image:hover {
  cursor: pointer;
}

/***************
 * POST FOOTER * 
 ***************/

/* REACTIONS */
.footer__reactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  color: var(--black);
  font-size: 15px;
}

.emojis__haha {
  z-index: 1;
  left: -2px;
}

.emojis__love {
  left: -0.4rem;
}

.footer__buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid $secondary_light;

  & > .dropdown {
    flex: 1 1;
    display: flex;
  }

  & > .dropdown button {
    display: flex;
    color: var(--black);
    justify-content: center;
    align-items: center;
    flex: 1 1;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 8px 10px;
    & svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
      color: $primary;
    }
    &:hover {
      background: $light;
    }
  }

  & > button {
    display: flex;
    color: var(--black);
    justify-content: center;
    align-items: center;
    flex: 1 1;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0.5rem 0;

    & svg {
      font-size: 1.5rem;
      color: $primary;
      margin-right: 0.5rem;
    }

    &:hover {
      background: $light;
    }
  }

  & button.buttons__like.active {
    color: #4994d0;
  }
}

.footer__comments {
  border-top: 1px solid $secondary_light;
  padding: 1rem 0;
  font-weight: 600;
  color: var(--black);
  display: none;
}

/* Write a comment.. box */
.comments__box {
  display: flex;
}

.box__profile {
  margin-right: 1rem;
  position: relative;
}

.box__profile:hover {
  cursor: pointer;
}

.profile__pic {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.box__bar {
  position: relative;
  flex: 1;
}

.bar__input {
  width: 100%;
  display: block;
  background: $light;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 40px;
  outline: none;
  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;
  word-wrap: break-word;
  padding-right: 6rem;
  resize: none;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.commentbtndiv {
  display: flex;
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 1;
  height: 100%;
  align-items: center;
}

.input-box input[type="text"].emojitextbox {
  padding-right: 50px;
}

aside.emoji-picker-react {
  position: absolute;
  z-index: 10;
  right: 0;
}

.commentsendbtn {
  color: #478bca;
  font-size: 18px;
  border: none;
  text-align: center;
  cursor: pointer;
  background: transparent;
  border-radius: 50%;
  width: 36px;
  line-height: 36px;
  padding: 0;
  cursor: pointer;

  & input[type="file"] {
    display: none;
  }

  & label {
    cursor: pointer;
  }

  &:hover {
    background: #f5f5f5;
  }
}

.comments__box .react-input-emoji--input {
  padding-right: 120px;
}

.react-input-emoji--input {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.react-input-emoji--input::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.react-input-emoji--button__show svg {
  fill: $primary;
}

/* Friends comments */
.comments__friend-comment {
  display: flex;
  text-align: start;
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.friend-comment__pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
  transition: filter 0.2s ease;
}

.friend-comment__pic:hover {
  filter: brightness(0.95);
}

.friend-comment__comment {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.friend-comment__commentinn {
  padding: 0.8rem;
  background: $light;
  border-radius: 0.8rem;
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.commentEdited {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 11px;
  width: 4px;
}

.comment__author {
  color: var(--black);
  font-size: 12px;
  display: flex;
}

.comment__content p {
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  margin: 0 0 0.6rem;

  &:last-child {
    margin: 0;
  }
}

.friend-comment__options {
  font-size: 15px;
  padding: 0.8rem;
  border-radius: 50%;
  line-height: 0.8rem;
  cursor: pointer;
  align-self: start;
}

.friend-comment__options:hover {
  background: $light;
}

.comment__reactions {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--white);
  border-radius: 2rem;
  padding: 0.1rem 0.4rem;
  font-size: 0.6rem;
  box-shadow: var(--box-shadow);
  transform: translate(50%, 50%);
}

.comment__reactions svg {
  margin-right: 0.3rem;
  color: $primary;
}

/* Like, reply and comment date */
.comment__links {
  color: $secondary;
  font-size: 12px;
  font-weight: 400;
  margin-top: 0.5rem;
  padding-left: 1rem;

  & button {
    background: transparent;
    border: none;
    padding: 0;
    color: inherit;
  }
}

.subcommentreply {
  display: none;
  width: 100%;
}

.comment__links span:after {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background: #d3d3d3;
  border-radius: 50%;
  margin: 0 1rem;
  vertical-align: middle;
}

.comment__links span:last-child:after {
  display: none;
}

.comment__replies:hover {
  cursor: pointer;
}

.links__like,
.links__reply {
  font-weight: 600;
}

.links__like.active {
  color: #4994d0;
}
.custom_accordian {
  border: 1px solid #ddd;
  background-color: var(--white);
  border-radius: 10px;

  .custom_accordian_header {
    button {
      width: 100%;
      text-align: left;
      background-color: transparent;
      border: none;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      color: var(--black);
      svg {
        margin-left: auto;
      }
    }
  }
}
.membercardbox {
  border: 1px solid #eee;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  &.myfollowerscard {
    padding: 0;
    label {
      padding: 1rem;
      display: block;
      .form-check {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        .form-check-input[type="checkbox"] {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
  }
}

.membercardboximg img {
  height: 5rem;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  width: 5rem;
  object-fit: cover;
}

.membercardboxdata {
  padding-top: 1rem;
}

.membercardboxdata h3 {
  font-size: 1rem;
  word-break: break-all;
  color: var(--black);
}

.membercardboxdata p {
  font-size: 0.8rem;
}

.formInputFontSize {
  font-size: 13px;
}

.servicecardbox {
  background-color: var(--white);
  padding: 1rem;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
}

.serviceratingdiv {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  background: $warning;
  color: var(--white);
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 4rem;
  padding: 3px 10px;
}

.serviceratingdiv svg {
  margin-right: 5px;
}

.redeemrequestbox {
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 1rem;
  .input-box {
    p {
      color: var(--black);
      font-size: 14px;
    }
  }
}

.service_type {
  font-size: 0.9rem;
  font-weight: 600;
  color: $primary;
  margin: 0 0 0.3rem;
}

.service_title {
  font-size: 1.2rem;
  color: var(--black);
  font-weight: 500;
  margin: 0 0 0.5rem;

  a:hover {
    color: $primary;
  }
}

.service_para {
  font-size: 0.8rem;
  color: $secondary;
  word-break: break-word;
  margin: 0 0 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.service_discount_para {
  color: $primary;
  font-size: 0.8rem;
  margin: 0 0 0.5rem;
}

.service_feature_para {
  color: $success;
  font-size: 0.8rem;
  margin: 0 0 0.5rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  padding: 0;
}

.wishlist_btn {
  font-weight: 600;
  color: $primary;
  margin: 0 0 0.5rem;
  width: 40px;
  text-align: center;
  background: #eee;
  display: inline-block;
  border-radius: 50%;
  border: none;
  line-height: 40px;
  padding: 0;

  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.service_mrp {
  font-weight: 600;
  color: var(--black);
  margin: 0 0 0.2rem;
}

.service_sp {
  font-size: 1.6rem;
  font-weight: 600;
  color: $primary;
  margin: 0 0 0.3rem;
}

.service_tax {
  font-size: 0.8rem;
  color: $secondary;
  margin: 0 0 0.7rem;
}

.service_amenities {
  color: $secondary;
  margin: 0;
  font-weight: 500;
}

.amenitiesicons {
  text-align: center;

  & img {
    height: 30px;
    margin: 0 0.7rem;
  }
}

.service_tag {
  position: absolute;
  left: -10px;
  top: 1rem;
  background: $gradient_primary;
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  padding: 5px 8px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    transform: translateY(100%);
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;

    border-bottom: 10px solid transparent;
    border-right: 10px solid #306ea3;
  }
}

.vendorreceivedcard {
  background-color: var(--white);
  padding: 0.8rem;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.vendorcardimg {
  position: relative;
}

.vendorcardimginn {
  position: relative;

  & img {
    width: 100%;
    border-radius: 0.5rem;
    height: 200px;
    object-fit: cover;
    border: 1px solid #eee;
  }
}

.vendorcarddata {
  padding-top: 0.8rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.vendorcardstatus {
  color: $primary;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0 0 0.5rem;
}

.vendorcardtitle {
  color: var(--black);
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.vendorcardprice {
  color: $primary;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0.5rem;

  & s {
    color: var(--black);
    font-size: 0.8rem;
    margin-left: 0.2rem;
  }

  & span {
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
}

.vendorcarddate {
  color: $secondary;
  font-size: 0.8rem;
  margin: 0 0 0.3rem;

  & span {
    color: var(--black);
    font-weight: 500;
  }
}

.vendorcardpara {
  color: $secondary;
  font-size: 0.8rem;
  margin: 0 0 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.vendorcardbtn {
  margin-top: 0.5rem;
  margin-top: auto;

  & .btn-sm {
    font-size: 11px;
  }
}

.vendorcardrating {
  margin-bottom: 0.3rem;

  & svg {
    margin-right: 0.3rem;
    color: $warning;
  }

  & i:last-child {
    margin-right: 0;
  }
}

.ticket_list_name {
  .form-check {
    padding: 0;
    .form-check-label {
      cursor: pointer;
      background-color: $light;
      display: block;
      padding: 10px 10px;
      padding-left: 30px;
      border-radius: 6px;
      color: #000;
      font-weight: 600;
      font-size: 14px;
    }
    &.active {
      .form-check-label {
        background-color: #fff2f2;
        span {
          text-decoration: line-through;
        }
      }
    }
  }
}

.vendortabs {
  & > .nav-tabs {
    gap: 1rem;
    border: none;
    margin-bottom: 15px;

    & > .nav-item {
      flex-grow: 1;
      text-align: center;

      & > .nav-link {
        background: $gradient_light;
        border-radius: 0.5rem;
        color: #000;
        border: none;
        border-bottom: 2px solid transparent;
        display: block;
        padding: 0.5rem 1rem;
        width: 100%;

        &.active {
          background: $gradient_primary;
          color: #fff;
        }
      }
    }
  }
}
.trip_detail_header {
  p {
    font-size: 14px;
    color: var(--black);
  }
  label {
    font-size: 13px;
    color: var(--black);
    margin-bottom: 5px;
  }
  .col-md {
    text-align: center;
  }
}
.taxisearchtabs {
  & .nav-tabs {
    gap: 1rem;
    border: none;
    margin-bottom: 15px;

    & .nav-item {
      flex-grow: 0;
      text-align: center;

      & .nav-link {
        background: $gradient_light;
        border-radius: 0.5rem;
        color: #000;
        border: none;
        border-bottom: 2px solid transparent;
        display: block;
        padding: 0.5rem 1rem;
        width: 100%;
        cursor: pointer;
        &.active {
          background: $gradient_primary;
          color: #fff;
        }
      }
    }
  }
}

.input-box {
  & .reactselectclass {
    & input[type="text"] {
      height: auto;
    }
  }
}

.forAuthorImg{
 img{
  height: 200px;
  width: 200px;
  border-radius: 50%;
 }
}

.forAuthorHeight{
  img{
    height: 200px !important;
    width: 100%;
    object-fit: cover;
  }
}

.searchinputrow {
  & .dropdown {
    height: 100%;

    & button {
      cursor: pointer;
    }
  }
}

.tripplaaningsec {
  background-size: cover;
  background-position: center;
}

.filtersmbx {
  border-bottom: 1px solid #e0e0e0;
}

.filterdrpbtn {
  button {
    padding: 15px 15px;
    font-size: 14px;
    position: relative;
    font-weight: 500;

    &:focus {
      box-shadow: none;
    }
  }
}

.filterdrpbtn a svg {
  position: absolute;
  padding: 19px 10px;
  transition: all 0.3s;
  color: var(--black);
  right: 0;
  top: 0;
}

.filterdrpbtn a.collapsed svg {
  transform: rotate(180deg);
}

.filterinner {
  padding: 0 15px 15px;
}

.sidefilterhead {
  background: $gradient_primary;
  padding: 10px 15px;
}

.sidefilter h3 {
  color: #fff;
  font-size: 20px;
  margin: 0;
  line-height: 1;
}

.sidefilter {
  background: var(--white);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  border-radius: 5px;
}

.checkboxlistmain:not(:last-child) {
  margin-bottom: 1.5rem;
}
.accordion-button:focus {
  box-shadow: none;
}
.checkboxlistmain h5 {
  font-size: 0.8rem;
  margin: 0 0 1rem;
}

.checkboxlistmain .form-check:not(:last-child) {
  margin-bottom: 1rem;
}

.checkboxlistmain .form-check label {
  color: var(--black);
  display: block;

  & svg {
    color: $primary;
    margin-right: 3px;
  }
}

.filtersmbx:last-child {
  border-bottom: none;
}

.flightlistcard {
  background-color: var(--white);
  padding: 1rem;
  transition: all 0.3s;
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
}

.flightlistcard:not(:last-child) {
  margin-bottom: 1rem;
}

.flightlistcard:hover {
  transform: scale(1.02);
  transition: all 0.3s;
}

.flightlistcard .airlinetext1 {
  font-size: 1.2rem;
  color: $secondary;
  font-weight: 600;
}

.filterstickybtn {
  display: none;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 99;
  width: 45px;
  height: 45px;
  &.filterstickybtnreturn {
    bottom: 9rem;
  }
}

.rmdp-ep-arrow {
  top: 1px !important;
}

.bgoverlay {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
}

.filterstickybtn button {
  background: $gradient_primary;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: $box-shadow;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  font-size: 15px;
  text-align: center;
}

.flightlistcard .airlinetext2 {
  font-size: 0.8rem;
  color: $secondary;
}

.flightlistcard .airlinetext3 {
  font-size: 0.8rem;
  color: $primary;
}

.flightlistcard .airlinetext4 {
  font-size: 1rem;
  color: $primary;
  font-weight: 600;
}

.flightlistcard .airlineimg {
  width: 4rem;
  height: 4rem;
}

.flightlistcard .airlineimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.flightlistcard .airlinename {
  font-size: 1rem;
  color: $secondary;
  margin-bottom: 0.3em;
}

.sidedarebox table td {
  padding: 5px 0;
}

.submitted input:valid {
  border-color: $primary;
}

.submitted input:invalid {
  border-color: red;
}

.submitted input:invalid:focus {
  outline-color: red;
}

.sidedarebox table td:last-child {
  text-align: right;
  font-weight: 500;
  color: var(--black);
}

.airlineicon {
  width: 4rem;
}

.airlineicon img {
}

.airlinedata h3 {
  color: $primary;
  font-size: 1.2rem;
}

.airlinedatebox {
  background-color: $light;
  border-radius: 5px;
  padding: 0.6rem;
}

.airlinedatebox label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

.airlinedatebox p {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--black);
  margin: 0;
  line-height: 1;
}

.airlinefeature {
  & ul {
    display: flex;
    flex-wrap: wrap;
  }

  & li {
    color: var(--black);
    font-size: 14px;
    font-weight: 600;
    width: 50%;
    margin-bottom: 0.8rem;
  }

  & li svg {
    color: $primary;
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
}

.loading_icon {
  text-align: center;

  & img {
    height: 50px;
    margin-bottom: 10px;
  }

  & p {
    color: var(--black);
    font-weight: bold;
  }
}

.loading_icon_full {
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 20000000012 !important;

  & p {
    margin: 10px 0 0;
    color: #000;
    font-weight: bold;
  }

  & img {
    width: 120px;
  }
}
.closebuttonrighttop {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  line-height: 30px;
  width: 30px;
  padding: 0;
}
.sliderwiththumbbox .main-slider-wrap .slick-slide img {
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.sliderwiththumbbox .thumbnail-slider-wrap .slick-slide img {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 5px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
}

.sliderwiththumbbox .thumbnail-slider-wrap .slick-current img {
  border-color: $primary;
}

.sliderwiththumbbox .slick-prev,
.sliderwiththumbbox .slick-next {
  top: 50%;
  z-index: 10;
}

.sliderwiththumbbox .slick-prev {
  left: 15px;
}

.sliderwiththumbbox .slick-next {
  right: 15px;
}

.deailaminitieslist {
  & li {
    color: var(--black);
    display: inline-block;
    margin-right: 2rem;

    & svg {
      margin-right: 1rem;
      color: $warning;
    }
  }
}

.detail_wishlist_btn {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;

  & .wishlist_btn {
    margin: 0;
  }
}

.normal-list {
  list-style: disc;
  padding-left: 2rem;
  & li {
    color: var(--black);
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.avatar_group {
  position: relative;
  display: inline-flex;
  .avatar_group_icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #fff;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
    &:not(:first-child) {
      margin-left: -15px;
      -webkit-mask: radial-gradient(
        circle 10px at 5px 50%,
        transparent 99%,
        #fff 100%
      );
      mask: radial-gradient(circle 10px at 5px 50%, transparent 99%, #fff 100%);
    }
  }
}

.timelinebgbox {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 30px;
  border-radius: 10px;
  h4 {
    font-style: italic;
    color: #fff;
  }
  ul {
    li {
      color: #bdbdbd;
    }
  }
}
.star-list {
  list-style: none;
  padding-left: 2rem;
  & li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    & svg {
      margin-left: -28px;
      margin-right: 10px;
      color: $warning;
    }
  }
}

.detailpricediv {
  & .mrp {
    font-size: 1.4rem;
    margin-right: 0.5rem;
    font-weight: 700;
    color: $primary;
  }

  & .sp {
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 600;
    color: #000;
  }

  & .off {
    font-size: 1rem;
    margin-right: 0.5rem;
    color: $success;
  }

  & .tax {
    font-size: 0.8rem;
    display: inline-block;
  }
}

.booking_icon_list {
  display: flex;
  gap: 5px;

  & svg {
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 24px;
    border-radius: 5px;
    color: var(--black);
  }
}

.batchselectcard {
  border: 1px solid #eee;
  border-radius: 5px;

  & label {
    display: flex;
    padding: 10px;
    cursor: pointer;

    & input[type="radio"] {
      cursor: pointer;
    }

    & .text1 {
      margin: 0 0 5px;
      color: var(--black);
      font-weight: 600;
    }

    & .text2 {
      font-size: 0.8em;
    }

    & .text2:not(:last-child) {
      margin: 0 0 5px;
    }
  }

  &:hover,
  &.active {
    border-color: $primary;
  }
}

.btn:hover {
  border-color: transparent;
}

.detailreviewcard {
  position: absolute;
  left: 1rem;
  top: 1rem;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  z-index: 10;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  & .flex-shrink-0 {
    margin-right: 0.5rem;

    & div {
      background: $gradient_primary;
      color: var(--white);
      width: 2rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: center;
      border-radius: 50%;
      font-size: 14px;
    }
  }

  & .flex-grow-1 {
    & h5 {
      font-weight: 600;
      margin: 0 0 1px;
      color: var(--black);
      font-size: 13px;
    }

    & p {
      color: var(--black);
      font-size: 12px;
    }
  }
}

.tripformdata {
  & h2 {
    color: $primary;

    & + p svg {
      margin: 0 1rem;
    }
  }

  & p {
    color: var(--black);

    & svg {
      margin-right: 0.5rem;
    }
  }
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  justify-content: center;
  background: no-repeat;
  border: none;
  padding: 0;
  outline: none;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid $secondary;
  width: 100%;
  top: 1.2rem;
  left: -50%;
  z-index: 2;
}

.stepper-item:not(:first-child):not(:last-child) .step-counter {
  font-weight: 600;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $secondary;
  margin: 0 auto 6px;
  color: #fff;
}

.stepper-item:first-child .step-counter,
.stepper-item:last-child .step-counter {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $secondary;
  margin: 0 auto 6px;
}

.stepper-item .step-name {
  font-size: 0.8rem;
  color: var(--black);
}

.stepper-item.completed:not(:first-child):not(:last-child) .step-name {
  color: $primary;
  font-weight: 600;
}

.stepper-item.active:not(:first-child):not(:last-child) .step-counter {
  background: $gradient_primary;
  color: #fff;
}

.stepper-item.active:not(:first-child):not(:last-child) .step-name {
  color: $primary;
  font-weight: 600;
}

.stepper-item.active:before {
  border-color: $primary;
}

.stepper-item:nth-child(2):before {
  border-color: $secondary;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.errorpagebox {
  text-align: center;

  & h2 {
    margin: 0 0 1rem;
    color: $primary;
    font-weight: bold;
  }

  & p {
    font-size: 1.2rem;
    margin: 0 0 2rem;
  }
}

.rmdp-input:focus {
  border: 0;
  box-shadow: none;
  outline: none !important;
}
.rmdp-arrow-container {
  align-items: center;

  &.disabled:hover {
    background: inherit;
    box-shadow: inherit;
  }

  &:hover {
    background: $gradient_primary;
    box-shadow: none;
  }
}

.rmdp-arrow {
  margin-top: 0px !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  box-shadow: none;
  background: $gradient_primary;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background: $gradient_primary;
}
.return_tabs_button {
  display: flex;
  gap: 10px;
  button {
    flex-grow: 1;
    background: $primary;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    opacity: 0.6;
    &.active {
      opacity: 1;
    }
    span {
      display: block;
      font-size: 0.8em;
    }
  }
}
.trip_sidebar_btn {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(calc(100% - 50px), -50%);
  z-index: 10;
  transition: all 0.3s;
  &:hover {
    transform: translate(5px, -50%);
    transition: all 0.3s;
  }
}
.offcanvas-body {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primary;
  }
}
.trip_sidebar_box {
  .text_category {
    font-size: 12px;
    color: $primary;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .text_title {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--black);
  }
  .text_date {
    font-size: 12px;
    color: $secondary;
  }
  .text_price {
    color: $success;
    font-weight: bold;
    font-size: 14px;
  }
}
.redeemrequestbox {
  &.cancelled {
    background-color: rgba(255, 0, 0, 0.03);
  }
}
.cancelledbadge {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 5px 14px;
  font-size: 12px;
  border-radius: 0 15px 0px 15px;
}
.sidebar_swap_icon {
  width: 40px;
  height: 40px;
  margin: -8px 0 -8px auto;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: $primary;
  }
}
.cmsbody {
  text-align: justify;

  & h4 {
    color: var(--black);
    margin: 0 0 1rem;
    font-size: 1.2rem;
  }

  & hr {
    margin: 1rem 0;
  }

  & ul {
    list-style: disc;
    padding-left: 15px;
    margin-bottom: 15px;

    & li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.profilembcardimg {
  width: 3.5rem;
  height: 3.5rem;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.imageuploadbtn {
  flex-grow: 1;

  & input {
    display: none;
  }

  & label {
    margin: 0;
    width: 100%;
  }
}
.emojipickermodal {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50px;
}
aside.emoji-picker-react {
  box-shadow: $box-shadow;
}
.emoji_button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  background: #fff;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.downloadappbutton {
  box-shadow: var(--box-shadow);
  background: $white;
  border: none;
  padding: 10px 20px;
  padding-right: 100px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  // span:nth-child(1) {
  //   flex-shrink: 0;
  //   margin-right:10px;
  //   font-weight: bold;
  //   color: #000;
  // }
  span:nth-child(1) {
    flex-grow: 1;
    text-align: left;
    color: #000;
    font-size: 16px;
  }
  span:nth-child(2) {
    flex-shrink: 0;
    margin-left: 10px;
    background-color: $primary;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 4px;
  }
}
.emoji-mart {
  width: 100% !important;
}

.react-input-emoji--container {
  margin: 0;
}

.react-emoji-picker--wrapper {
  top: 40px;
  bottom: auto;
}

.selectedvideo {
  width: 100%;
  display: block;
}

// .dropdown-menu {
//   font-size: 14px;
//   min-width: 12rem;
//   & .dropdown-item {
//     padding: 7px 15px;
//   }
// }
.read-more-less--more,
.read-more-less--less {
  color: $primary;
  cursor: pointer;
}

.transacicon {
  width: 60px;
  border: 1px solid #eee;
  border-radius: 50%;
  height: 60px;
  overflow: hidden;
  padding: 10px;

  & img {
    width: 100%;
  }
}

.redeemrequestbox {
  & .input-box {
    & label {
      margin-bottom: 1px;
      font-size: 12px;
    }
  }
}

.themetoggle {
  & input[type="checkbox"] {
    display: none;
  }

  .moon {
    color: pink;
  }

  .sun {
    color: #ffbc00;
  }
  button {
    span {
      display: block;
    }
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 13px;
    transform: scale(1.2);
    border: none;
    padding: 0;
    .sun {
      color: #fff;
    }
  }
}

.stickysidebar {
  position: sticky;
  top: 1rem;
}

.cookiebox {
  position: fixed;
  bottom: 1rem;
  z-index: 100;
  background: $secondary_light;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 1rem;

  & h5 {
    color: $black;
  }
}

.password_show {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  height: 100%;
  font-size: 20px;
  padding: 0 15px;
  color: $success;
  display: flex;
  align-items: center;
}

.form_error {
  color: $danger;
  font-weight: 500;
  margin-top: 5px;
  font-size: 12px;
}

.after_login_menu {
  padding: 0px;
  border: none;

  & .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  &:active {
    border: none;
  }

  &:hover {
    border: none;
  }

  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  & span {
    margin: 0 10px;
    font-size: 14px;
    font-weight: 500;
  }
}

.commentimgbox {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);

  & img {
    height: 100px;
  }

  & video {
    height: 100px;
    display: block;
  }

  & .delete_item {
    background-color: $danger;
    color: #fff;
    border: none;
    width: 30px;
    outline: none;
    font-size: 13px;
    border-radius: 50%;
    line-height: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}

.viewtriplistdivmain {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .viewtriplistdivmain_header {
    border-bottom: 2px solid $primary;
    padding-bottom: 15px;
    margin-bottom: 15px;
    h5 {
      color: $primary;
      margin: 0;
    }
  }
  & .text_category {
    font-size: 12px;
    color: $primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }

  & .text_title {
    font-size: 13px;
    label {
      color: #5a5a5a;
      font-weight: bold;
      display: block;
      margin-bottom: 3px;
    }
    p {
    }
  }

  & .booking_inner {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }

  & .booking_inner:first-child {
    padding-top: 0;
  }

  & .booking_inner:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  & .trip_booking {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  & .trip_booking:last-child {
    margin-bottom: 0px;
  }
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  line-height: 0;
}

.editordiv {
  border: 1px solid #dee2e6;

  & * {
    box-sizing: content-box;
  }
}

.editordiv .rdw-editor-toolbar {
  margin: 0;
  border: none;
  border-bottom: 1px solid #dee2e6;
}

.public-DraftStyleDefault-block {
  margin: 0.5rem 0;
}

.rdw-editor-main {
  padding: 20px;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
  cursor: text;
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: 2 !important;
}

.servicecardboximg {
  position: relative;

  & img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

.service_list_three_img_box {
  position: relative;

  & img {
    height: 100px;
    object-fit: cover;
  }

  & .delete_icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: $danger;
    color: #fff;
    border: none;
    border-radius: 50%;
    line-height: 30px;
    width: 30px;
    padding: 0;
    font-size: 15px;
  }
}

.delete_icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background: $danger;
  color: #fff;
  border: none;
  border-radius: 50%;
  line-height: 30px;
  width: 30px;
  padding: 0;
  font-size: 15px;
}

.pagination {
  gap: 5px;
  justify-content: center;

  & .page-item {
    & .page-link {
      padding: 5px 15px;
      border-radius: 4px;
      border: 2px solid #eee;

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      & .page-link {
        border-color: $primary;
      }
    }

    &.disabled {
      & .page-link {
        background-color: #eee;
      }
    }
  }
}
.seat_available_row:not(:last-child) {
  margin-bottom: 5px;
}
.airlinetextround {
  text-align: center;
  margin: 5px 0 0;
  &.midstop {
    & span {
      background-color: $warning;
      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #42c161;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        border: 3px solid #fff;
      }
    }
  }
  & span {
    display: block;
    margin: 0 auto 5px;
    height: 3px;
    background-color: $success;
    width: 80px;
    position: relative;
    border-radius: 5px;
  }
  & small {
    display: block;
    font-size: 0.8em;
  }
}

.forBorderTop{
  border-top: 1.5px solid #e7e7e7;
}
.forBorderBottom{
  border-bottom: 1.5px solid #e7e7e7;
}

.careeraccordion .accordion-button.collapsed {
  border-radius: 1rem;
}
.appicons {
  img {
    height: 35px;
  }
}

.farerule-content {
  overflow: auto;
  color: var(--black);
  ul {
    li {
      margin-bottom: 10px;
    }
  }
  & table {
    & tr {
      border-bottom: 1px solid var(--black);
    }
    & td {
      padding: 10px;
      border-right: 1px solid var(--black);
    }
  }
}
.flight_loading_icon {
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
}
.flight_details h4 {
  font-size: 14px;
  color: var(--black);
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.flight_details p {
  font-size: 13px;
  text-align: left;
  margin-bottom: 10px;
}
.Toastify__toast-container {
  z-index: 99999999999 !important;
  font-size: 14px;
}

.room_image{
  img{
    height: 44px;
    object-fit: cover;
  }
}
@import "darkmode";
@import "responsive";
