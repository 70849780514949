html[data-theme="light"] {
  --white: #fff;
  --black: #000;
}

html[data-theme="dark"] {
  --black: #fff;
  --white: #000;
  --box-shadow: 0 1px 1px 0px rgba(255, 255, 255, 0.1) !important;

  body,
  .text-dark,
  .flightlistcard .airlinetext1,
  .flightlistcard .airlinename,
  .flatpackagedata,
  .flatofferbigdata h5,
  .slick-dots li button:before,
  .input-box input[type="text"],
  .input-box input[type="tel"],
  .input-box input[type="email"],
  .input-box input[type="file"],
  .input-box input[type="date"],
  .input-box input[type="password"],
  .input-box input[type="number"],
  .input-box textarea,
  .footnewsletter input[type="email"],
  .footnewsletter input[type="tel"],
  .footnewsletter input[type="text"],
  .input-box select,
  .react-input-emoji--container,
  .input-box label,
  .table,
  .table-striped>tbody>tr:nth-of-type(odd)>*,
  .dropdown-item {
    color: var(--black) !important;
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23478bca'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }

  .bg-white,
  .modal-content {
    background-color: var(--white) !important;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(129 129 129)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .accordion-item,
  .alert-info {
    color: #fff;
  }

  .searchrowbox {
    background-color: transparent;
    border-color: $secondary;
    color: #fff;
  }

  .searchrowbox select option {
    color: #000;
  }
  .bg2,
  .slick-prev:before,
  .slick-next:before,
  .footnewsletter input[type="email"],
  .footnewsletter input[type="tel"],
  .footnewsletter input[type="text"],
  .input-box input[type="text"],
  .input-box input[type="tel"],
  .input-box input[type="email"],
  .input-box input[type="file"],
  .input-box input[type="date"],
  .input-box input[type="password"],
  .input-box input[type="number"],
  .input-box textarea,
  .input-box select,
  .airlinedatebox,
  .vendortabs .nav li a,
  .dropdown-menu,
  .css-1s2u09g-control,
  .form-control,
  .form-select,
  .form-select option,
  .alert-info,
  .roundtrip_card>input[type="radio"]:checked+.roundtrip_card_label,
  .react-autosuggest__suggestions-container--open,
  .editprofiletabs.profiletabspage .nav-tabs,
  .offcanvas {
    background-color: #1a1a1a;
  }

  .react-autosuggest__suggestions-container--open {
    border-color: #202020;
  }

  .react-autosuggest__suggestion--highlighted {
    background: #000;
  }

  .react-autosuggest__suggestions-list li:not(:last-child) {
    border-bottom: 1px solid #323232;
  }

  .submenu,
  .searchbanner.bg-primary,
  .searchbanner1.bg-primary {
    background: #1a1a1a !important;
  }

  .btn-close,
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: #8b8b8b;
  }

  .timer-wrap.bg-info {
    background: #383838 !important;
  }

  .gradient2,
  .title_header,
  .post__options:hover,
  .buttons__like:hover,
  .buttons__comment:hover,
  .buttons__share:hover,
  .tooltip-inner,
  .tooltip.bs-tooltip-right .arrow:before,
  .notificationlist,
  .tooltip.bs-tooltip-left .arrow:before,
  .tooltip.bs-tooltip-bottom .arrow:before,
  .profilesubmenu,
  .bg-light,
  .prefencelistbox .form-switch label,
  .tooltip.bs-tooltip-top .arrow:before,
  .addpostlink .postuploadbutton,
  .react-input-emoji--container,
  .friend-comment__commentinn,
  .friend-comment__options:hover,
  .alert-primary,
  .topheader.bg-primary {
    background: linear-gradient(45deg, #1a1a1a 0%, #383838 100%);
  }

  .notification_dropdown .dropdown-menu ul li .title {
    color: #a5a5a5;
  }

  .notification_dropdown .dropdown-menu ul li:hover {
    background-color: #292929;
  }

  .notification_dropdown .dropdown-menu ul li.active .title {
    color: #fff;
  }

  .alert-primary {
    border: none;
  }

  .filtersmbx,
  .accordion-item,
  .footer__buttons,
  .profilemenu>ul>li:not(:last-child),
  .accordion-flush .accordion-collapse,
  .modal-header,
  .modal-footer,
  .forBorderTop,
  .forBorderBottom,
  .modal-content,
  .navbar-toggler {
    border-color: #1a1a1a;
  }

  .shadow,
  .shadow-sm,
  .servicecardbox,
  .vendorreceivedcard {
    box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, 0.4) !important;
  }

  [class*="-singleValue"] {
    color: #dbdbdb;
  }

  [class*="-option"] {
    color: #000;
  }

  [class*="-control"],
  [class*="-select"] {
    background-color: #000;
    color: #fff;
  }

  [class*="css-"] [class*="-Svg"] {
    color: #fff;
  }

  [class*="-Input"] [id*="react-select-"] {
    color: #fff !important;
  }

  [class*="-control"] [role="combobox"] {
    color: #fff !important;
  }

  .themetoggle button {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #3e3e3e;
  }
}