@import "colors";
// Variable overrides
$btn-padding-y: 0.4rem;
$btn-padding-x: 1.2rem;
$btn-padding-y-sm: 0.3rem;
$btn-padding-x-sm: 1rem;
$btn-padding-y-lg: 0.7rem;
$btn-padding-x-lg: 1.8rem;

$font1: "Poppins", sans-serif;

$gradient_primary: linear-gradient(45deg, $primary 0%, $primary_light 100%);
$gradient_light: linear-gradient(45deg, #d6ecf3 0%, #f8f8f9 100%);
$gradient_secondary: linear-gradient(45deg, $secondary 0%, #cbcbcb 100%);

.btn-primary {
  background: $gradient_primary;
  border: none !important;
  color: #fff !important;
}
.btn-secondary {
  color: #fff !important;
}
.btn-success {
  color: #fff !important;
}
.bg-primary {
  background: $gradient_primary;
}

:root {
  --white: white;
  --black: black;
  --box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.fw-medium {
  font-weight: 500;
}



@import "bootstrap";
